<template>
	<div class="animated fadeIn">
		<b-card-group deck>
			<b-card header-tag="header">
				<h5
					slot="header"
					class="mb-0">
					{{ translate('general') }}
				</h5>
				<div class="col-12">
					<button
						:style="(['xs'].includes(windowWidth) ? 'display:block; max-width: 100%!important;' : '')"
						class="btn btn-sm btn-primary btn-custom col-5"
						@click="executeCommand('migrate')">
						{{ translate('run_migrations') }}
					</button>
					<button
						:style="(['xs'].includes(windowWidth) ? 'display:block; max-width: 100%!important;' : '')"
						class="btn btn-sm btn-primary btn-custom col-5"
						@click="executeCommand('cache_clear')">
						{{ translate('clear_cache') }}
					</button>
				</div>
				<br>
				<div class="col-12">
					<h6>{{ translate('execute_seeder') }}</h6>
					<div class="form-inline">
						<input
							v-model="param1"
							:placeholder="translate('seeder_name')"
							class="form-control form-control-sm col-5"
							aria-label="Large">
						<button
							class="btn btn-text btn-sm btn-primary col-5"
							@click="executeCommand('db_seed')">
							{{ translate('run_seeder') }}
						</button>
					</div>
				</div>

				<hr>
				<div class="col-12">
					<h6>{{ translate('console_output') }}</h6>
					<div class="p-2 console">
						<samp>{{ output }}</samp>
					</div>
				</div>
			</b-card>

			<b-card header-tag="header">
				<h5
					slot="header"
					class="mb-0">
					{{ translate('verifications') }}
				</h5>
				<div class="col-12">
					<button
						:style="(['xs'].includes(windowWidth) ? 'display:block; max-width: 100%!important;' : '')"
						class="btn btn-sm btn-primary btn-custom col-5"
						@click="executeCommand('verify_short_term_data')">
						{{ translate('short_term') }}
					</button>
					<button
						:style="(['xs'].includes(windowWidth) ? 'display:block; max-width: 100%!important;' : '')"
						class="btn btn-sm btn-primary btn-custom col-5"
						@click="executeCommand('verify_mid_term_data')">
						{{ translate('mid_term') }}
					</button>
					<button
						:style="(['xs'].includes(windowWidth) ? 'display:block; max-width: 100%!important;' : '')"
						class="btn btn-sm btn-primary btn-custom col-5"
						@click="executeCommand('verify_long_term_data')">
						{{ translate('long_term') }}
					</button>
					<button
						:style="(['xs'].includes(windowWidth) ? 'display:block; max-width: 100%!important;' : '')"
						class="btn btn-sm btn-primary btn-custom col-5"
						@click="executeCommand('verify_binary_tree')">
						{{ translate('binary_tree') }}
					</button>
					<button
						:style="(['xs'].includes(windowWidth) ? 'display:block; max-width: 100%!important;' : '')"
						class="btn btn-sm btn-primary btn-custom col-5"
						@click="executeCommand('verify_tree')">
						{{ translate('tree') }}
					</button>
					<button
						:style="(['xs'].includes(windowWidth) ? 'display:block; max-width: 100%!important;' : '')"
						class="btn btn-sm btn-primary btn-custom col-5"
						@click="executeCommand('verify_orders')">
						{{ translate('orders') }}
					</button>
				</div>
			</b-card>

			<b-card header-tag="header">
				<h5
					slot="header"
					class="mb-0">
					{{ translate('crons') }}
				</h5>
				<div class="col-12">
					<button
						:style="(['xs'].includes(windowWidth) ? 'display:block; max-width: 100%!important;' : '')"
						class="btn btn-sm btn-primary btn-custom col-5"
						@click="executeCommand('qualify_distributors')">
						{{ translate('qualify_distributors') }}
					</button>
					<button
						:style="(['xs'].includes(windowWidth) ? 'display:block; max-width: 100%!important;' : '')"
						class="btn btn-sm btn-primary btn-custom col-5"
						@click="executeCommand('run_autoship')">
						{{ translate('autoship') }}
					</button>
					<!-- <button
						:style="(['xs'].includes(windowWidth) ? 'display:block; max-width: 100%!important;' : '')"
						class="btn btn-sm btn-primary btn-custom col-5"
						@click="executeCommand('renew_memberships')">
						{{ translate('renew_memberships') }}
					</button> -->
					<button
						:style="(['xs'].includes(windowWidth) ? 'display:block; max-width: 100%!important;' : '')"
						class="btn btn-sm btn-primary btn-custom col-5"
						@click="executeCommand('update_marketing_emails')">
						{{ translate('update_emails') }}
					</button>
					<button
						:style="(['xs'].includes(windowWidth) ? 'display:block; max-width: 100%!important;' : '')"
						class="btn btn-sm btn-primary btn-custom col-5"
						@click="executeCommand('send_notifications')">
						{{ translate('send_notifications') }}
					</button>
				</div>
			</b-card>
		</b-card-group>
	</div>
</template>
<script>

import WindowSizes from '@/mixins/WindowSizes';
import { CommandPanel as messages, Validations } from '@/translations';
import CommandPanel from '@/util/CommandPanel';

export default {
	name: 'CommandPanel',
	messages: [messages, Validations],
	mixins: [WindowSizes],
	data() {
		return {
			alert: new this.$Alert(),
			settings: new CommandPanel(),
			param1: '',
			output: '',
		};
	},
	computed: {
		errors() {
			return this.settings.data.errors.errors;
		},
	},
	methods: {
		executeCommand(command) {
			const trans = {
				title: this.translate('execute_command') + command,
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};

			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				const payload = {
					command,
					param1: this.param1,
					password: password.value,
				};

				this.settings.executeCommand(payload).then((response) => {
					this.output = response.response.message;
					this.alert.toast('success', this.translate('command_executed'), { timer: 6000 });
				}).catch(() => {
					let errorName = 'default_error_message';
					if (this.hasError('password')) {
						errorName = 'incorrect_password';
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
	},
};
</script>

<style scoped>
	.btn-custom  {
		display: inline-block;
		margin-bottom: 5px;
		margin-right: 5px;
	}
	.console {
		min-height:100px;
		background-color: #474441;
		color:#BDC7CB;
		overflow: auto;
	}

	@media only screen and (max-width: 1724px) {
		.btn-custom  {
			min-height: 50px;
		}
	}

	@media only screen and (max-width: 574px) {
		.btn-custom  {
			min-height: initial;
		}
	}

	@media only screen and (max-width: 410px) or (min-width: 590px), (max-width: 1200px) {
		.btn-custom, .btn-text, input  {
			display: block;
			max-width: 100%!important;
			flex: 0 0 100%;
		}
	}
</style>
