import { EXECUTE_COMMAND } from '@/config/endpoint';
import Req from './AxiosRequest';

class CommandPanel {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = this.data.errors;
	}

	executeCommand(payload) {
		const { method, endpoint } = EXECUTE_COMMAND;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default CommandPanel;
